"use client";
import { useEffect, useState } from "react";
import { ActionIconButtonKT } from "../resuables/Button";
import { smallIconSize } from "../../utils/iconsize";
import Share from "../icons/share";

interface ShareButtonItems {
    title: string;
    feature_img: string;
    slug: string;
}

export default function ShareButton(props: ShareButtonItems) {
    const [isSupported, setIsSupported] = useState(false);

    useEffect(() => {
        if (
            typeof navigator !== "undefined" &&
            typeof navigator.share === "function"
        ) {
            setIsSupported(true);
        }
    }, []);

    const handleShare = async () => {
        try {
            await navigator.share({
                title: props.title,
                url: window.location.href,
            });
            console.log("Content shared successfully");
        } catch (error) {
            console.error("Error sharing content:", error);
        }
    };

    return (
        <>
            <ActionIconButtonKT
                aria-label="share"
                onClick={handleShare}
                disabled={!isSupported}
            >
                <Share
                    width={smallIconSize.width}
                    height={smallIconSize.height}
                    size={smallIconSize.size}
                />
            </ActionIconButtonKT>
        </>
    );
}
