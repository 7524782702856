"use client";
import React, { useState, useEffect } from "react";
import { Affix, Text } from "@mantine/core";
import { ClearOutlineButton } from "../../components/resuables/Button";
import styles from "./policy.module.css";

export default function CookieConsentBanner() {
    const [acceptCookies, setAcceptCookie] = useState<boolean | null>(null);
    const [isVisible, setIsVisible] = useState<boolean | null>(null);

    useEffect(() => {
        const consent = localStorage.getItem("acceptCookies");
        if (consent === "true") {
            setAcceptCookie(true);
            setIsVisible(false);
        } else {
            setAcceptCookie(false);
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        setAcceptCookie(true);
        setIsVisible(false);
        localStorage.setItem("acceptCookies", "true");
    };

    if (!isVisible) {
        return null;
    }

    return (
        <Affix
            position={{ bottom: 0, left: 0 }}
            classNames={{ root: styles.cookieaffix }}
        >
            <section className={styles.cookiebanner}>
                <p className={styles.cookiepara}>
                    We use cookies for essential functions such as enabling trip
                    booking. By continue using the website you accept our{" "}
                    <Text
                        component="span"
                        inherit
                        className={styles.cookieLink}
                        onClick={() => (window.location.href = "/privacy")}
                    >
                        cookie policies
                    </Text>
                    .
                </p>
                <ClearOutlineButton onClick={handleAccept}>
                    Accept
                </ClearOutlineButton>
            </section>
        </Affix>
    );
}
