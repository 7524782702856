"use client";
import { useState } from "react";
import { Modal } from "@mantine/core";
import PrimaryButton from "../resuables/Button";
import { ActionIconButtonKT } from "../resuables/Button";
import { smallIconSize } from "../../utils/iconsize";
import View from "../icons/eye";
import styles from "./exp.module.css";

interface ListingItems {
    title: string;
    overview: string;
    slug: string;
}

export default function ViewButton(props: ListingItems) {
    const [opened, setOpened] = useState(false);

    return (
        <>
            <ActionIconButtonKT
                aria-label="view"
                onClick={() => setOpened(true)}
            >
                <View
                    width={smallIconSize.width}
                    height={smallIconSize.height}
                    size={smallIconSize.size}
                />
            </ActionIconButtonKT>
            <Modal
                opened={opened}
                radius="md"
                size="auto"
                onClose={() => setOpened(false)}
            >
                <section className={styles.overviewModal}>
                    <h5 className={styles.overviewModalTitle}>
                        Experience Overview
                    </h5>
                    <p className={styles.overviewModalText}>{props.overview}</p>
                    <PrimaryButton
                        onClick={() =>
                            (window.location.href = `/experience/${props.slug}`)
                        }
                    >
                        Book now
                    </PrimaryButton>
                </section>
            </Modal>
        </>
    );
}
