"use client";
import { useQuery } from "@tanstack/react-query";
import { getFavourite } from "../../src/app/api/listingFunction";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSession } from "next-auth/react";
import { FavActionIconButtonKT } from "../resuables/Button";
import Heart from "../icons/heart";
import { smallIconSize } from "../../utils/iconsize";

interface FavouriteButtonProps {
    listing_id: number;
}

export default function FavouriteButton({ listing_id }: FavouriteButtonProps) {
    const { data: session } = useSession();
    const userEmail = session?.user?.email;
    const [isFavourite, setIsFavourite] = useState(false);

    const { data: favouriteData } = useQuery({
        queryKey: ["favourites", userEmail],
        queryFn: ({ queryKey }) => getFavourite(queryKey[1] as string),
        enabled: !!userEmail,
    });

    // Update `isFavourite` state based on fetched data
    useEffect(() => {
        if (favouriteData) {
            const flattenedListingIDs = favouriteData.flatMap(
                (item: FavouriteButtonProps) => item.listing_id
            );
            setIsFavourite(flattenedListingIDs.includes(listing_id));
        }
    }, [favouriteData, listing_id]);

    const toggleFavouriteClick = async () => {
        if (!userEmail) {
            alert("Please sign in to add to favourites");
            return;
        }

        try {
            await axios.post(`/api/toggleFavourite`, {
                email: userEmail,
                listing_id: listing_id,
                action: isFavourite ? "remove" : "add",
            });
            setIsFavourite(!isFavourite);
        } catch (error) {
            console.error("Error toggling favourite status:", error);
        }
    };

    return (
        <FavActionIconButtonKT
            onClick={toggleFavouriteClick}
            aria-label="favourite"
            variant={isFavourite ? "filled" : "subtle"}
            size="sm"
        >
            <Heart
                size={smallIconSize.size}
                width={smallIconSize.width}
                height={smallIconSize.height}
            />
        </FavActionIconButtonKT>
    );
}
