"use Client";
import { useQuery } from "@tanstack/react-query";
import { currencyConversion } from "../../src/app/api/listingFunction";
import { NumberFormatter, Loader } from "@mantine/core";
import styles from "./exp.module.css";

interface ExpItem {
    base_currency: string;
    price: number;
    iscustom_price: boolean;
    price_text: string;
}

export default function ListingPriceCard(props: ExpItem) {
    const userCurrencySign = localStorage?.getItem("userCurrencySign") || "£";
    const userCurrencyCode = localStorage?.getItem("userCurrencyCode") || "GBP";
    const isCustomPrice = props.iscustom_price;
    const from = props.base_currency;
    const to = userCurrencyCode;
    const amount = props.price;

    const { isLoading, isError, data } = useQuery({
        queryKey: ["currencyConversion", from, to, amount],
        queryFn: ({ queryKey }) =>
            currencyConversion(
                queryKey[1] as string,
                queryKey[2] as string,
                queryKey[3] as number
            ),
        enabled: !isCustomPrice,
    });

    if (isLoading) return <Loader color="#4d3594" size="xs" />;
    if (isError) return null;

    return (
        <>
            {isCustomPrice ? (
                <p className={styles.pricelabel}>{props.price_text}</p>
            ) : (
                <p className={styles.pricelabel}>
                    From: {userCurrencySign}{" "}
                    <NumberFormatter
                        value={data}
                        thousandSeparator
                        decimalScale={2}
                    />
                </p>
            )}
        </>
    );
}
