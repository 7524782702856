import ViewButton from "./QuickView";
import ShareButton from "../resuables/ShareButton";
import FavouriteButton from "./FavouriteButton";
import styles from "./exp.module.css";

interface ListingItems {
    title: string;
    overview: string;
    slug: string;
    feature_img: string;
    listing_id: number;
}

export default function ListingAction(props: ListingItems) {
    return (
        <section className={styles.cardbutton}>
            <FavouriteButton listing_id={props.listing_id} />
            <ViewButton
                title={props.title}
                overview={props.overview}
                slug={props.slug}
            />
            <ShareButton
                title={props.title}
                slug={props.slug}
                feature_img={props.feature_img}
            />
        </section>
    );
}
