import axios from "axios";

export const getAlsoLikeListing = async (created_by: string, id: number) => {
    const response = await axios.get(
        `/api/alsoLikeListing?created_by=${created_by}&id=${id}`
    );
    return response.data.alsoLikeListing;
};

export const getFavourite = async (userEmail: string) => {
    const response = await axios.get(
        `/api/getFavourite?userEmail=${userEmail}`
    );
    return response.data.favouriteListings;
};

export const currencyConversion = async (
    from: string,
    to: string,
    amount: number
) => {
    const response = await axios.post(`/api/currencyConversion`, {
        from,
        to,
        amount,
    });
    return response.data.result;
};

export const getIndexListing = async (campaign: string) => {
    const response = await axios.get(`/api/home?campaign=${campaign}`);
    return response.data.featureListing;
};

export const getExperience = async (slug: string) => {
    const response = await axios.get(`/api/experience/${slug}`);
    return response.data.exp;
};
