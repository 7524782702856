import { Skeleton } from "@mantine/core";
import styles from "./resuables.module.css";

export default function SkeletonLoaderHero() {
    return (
        <div>
            <Skeleton
                height={320}
                classNames={{ root: styles.skeletonheroroot }}
            />
        </div>
    );
}

export function SkeletonLoaderPage() {
    return (
        <div>
            <Skeleton
                height={15}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                width="70%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={1}
                animate={false}
                classNames={{ root: styles.skeletonpageholderroot }}
            />
        </div>
    );
}

export function SkeletonLoaderCard() {
    return (
        <div>
            <Skeleton
                height={70}
                width="30%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={25}
                width="30%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={25}
                width="30%"
                classNames={{ root: styles.skeletonpageroot }}
            />

            <Skeleton height={1} width={1} mt="250px" />
        </div>
    );
}

export function SkeletonLoaderExpPage() {
    return (
        <div className={styles.exppageskeleton}>
            <Skeleton
                height={70}
                width="30%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                width="70%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={1}
                animate={false}
                classNames={{ root: styles.skeletonpageholderroot }}
            />
        </div>
    );
}
